<template>
  <div style="min-height: 750px;" v-if="d_userShopWdm">
    <b-row>
      <b-col sm="12" lg="2"></b-col>
      <b-col sm="12" lg="8"></b-col>
      <b-col sm="12" lg="2"></b-col>
    </b-row>
    <b-row style="margin-bottom: 20px; border-bottom: solid 1px whitesmoke;">
      <b-col sm="12" md="2" lg="2"> </b-col>
      <b-col sm="12" md="4" lg="4">
        <i class="fa fa-desktop"></i>
        {{ StoreLangTranslation.data['order_list'][StoreLang] }}
      </b-col>
      <b-col sm="12" md="4" lg="4" :style="StoreDevice.isMobile ? '' : 'text-align: right;'"></b-col>
      <b-col sm="12" md="2" lg="2"></b-col>
    </b-row>
    <b-row>
      <b-col sm="12" lg="2"></b-col>
      <b-col sm="12" lg="8">
        <b-row style="margin: 3px;">
          <b-col sm="12" lg="4">
            {{ d_userShopWdm['0']['parameters']['0']['name']['translation'][StoreLang] }}
          </b-col>
          <b-col sm="12" lg="8">
            <select class="form-control" v-model="d_userShopData.status">
              <option v-for="(op, op_index) in d_userShopWdm['0']['parameters']['0'].options" :value="op.value">
                {{ op.translation[StoreLang] }}
              </option>
            </select>
          </b-col>
        </b-row>
        <b-row style="margin: 3px;">
          <b-col sm="12" lg="4">
            {{ d_userShopWdm['0']['parameters']['1']['name']['translation'][StoreLang] }}
          </b-col>
          <b-col sm="12" lg="8">
            <b-row style="margin: 3px;" v-for="(lang, lang_index) in StoreLangList.data">
              <b-col sm="12" lg="4">
                {{ lang.label }}
              </b-col>
              <b-col sm="12" lg="8">
                <input type="text" class="form-control" v-model="d_userShopData.shop_name[lang.label]">
              </b-col>
            </b-row>
          </b-col>
        </b-row>
        <b-row style="margin: 3px;">
          <b-col sm="12" lg="4">
            {{ d_userShopWdm['0']['parameters']['2']['name']['translation'][StoreLang] }}
          </b-col>
          <b-col sm="12" lg="8">
            <b-row style="margin: 3px;" v-for="(lang, lang_index) in StoreLangList.data">
              <b-col sm="12" lg="4">
                {{ lang.label }}
              </b-col>
              <b-col sm="12" lg="8">
                <input type="text" class="form-control" v-model="d_userShopData.shop_logo[lang.label]">
              </b-col>
            </b-row>
          </b-col>
        </b-row>
        <b-row style="margin: 3px;">
          <b-col sm="12" lg="4">
            {{ d_userShopWdm['0']['parameters']['3']['name']['translation'][StoreLang] }}
          </b-col>
          <b-col sm="12" lg="8">
            <b-row style="margin: 3px;" v-for="(lang, lang_index) in StoreLangList.data">
              <b-col sm="12" lg="4">
                {{ lang.label }}
              </b-col>
              <b-col sm="12" lg="8">
                <input type="text" class="form-control" v-model="d_userShopData.shop_address[lang.label]">
              </b-col>
            </b-row>
          </b-col>
        </b-row>
        <b-row style="margin: 3px;">
          <b-col sm="12" lg="4">
            {{ d_userShopWdm['0']['parameters']['4']['name']['translation'][StoreLang] }}
          </b-col>
          <b-col sm="12" lg="8">
            <b-row style="margin: 3px;" v-for="(lang, lang_index) in StoreLangList.data">
              <b-col sm="12" lg="4">
                {{ lang.label }}
              </b-col>
              <b-col sm="12" lg="8">
                <input type="text" class="form-control" v-model="d_userShopData.shop_tax_office[lang.label]">
              </b-col>
            </b-row>
          </b-col>
        </b-row>
        <b-row style="margin: 3px;">
          <b-col sm="12" lg="4">
            {{ d_userShopWdm['0']['parameters']['5']['name']['translation'][StoreLang] }}
          </b-col>
          <b-col sm="12" lg="8">
            <b-row style="margin: 3px;" v-for="(lang, lang_index) in StoreLangList.data">
              <b-col sm="12" lg="4">
                {{ lang.label }}
              </b-col>
              <b-col sm="12" lg="8">
                <input type="text" class="form-control" v-model="d_userShopData.shop_tax_number[lang.label]">
              </b-col>
            </b-row>
          </b-col>
        </b-row>
        <b-row style="margin: 3px;">
          <b-col sm="12" lg="4">
            {{ d_userShopWdm['0']['parameters']['6']['name']['translation'][StoreLang] }}
          </b-col>
          <b-col sm="12" lg="8">
            <b-row style="margin: 3px;" v-for="(lang, lang_index) in StoreLangList.data">
              <b-col sm="12" lg="4">
                {{ lang.label }}
              </b-col>
              <b-col sm="12" lg="8">
                <input type="text" class="form-control" v-model="d_userShopData.mersis_no[lang.label]">
              </b-col>
            </b-row>
          </b-col>
        </b-row>
        <b-row style="margin: 3px;">
          <b-col sm="12" lg="4">
            {{ d_userShopWdm['0']['parameters']['7']['name']['translation'][StoreLang] }}
          </b-col>
          <b-col sm="12" lg="8">
            <b-row style="margin: 3px;" v-for="(lang, lang_index) in StoreLangList.data">
              <b-col sm="12" lg="4">
                {{ lang.label }}
              </b-col>
              <b-col sm="12" lg="8">
                <input type="text" class="form-control" v-model="d_userShopData.tax_rate_percent[lang.label]">
              </b-col>
            </b-row>
          </b-col>
        </b-row>
        <b-row style="margin: 3px;">
          <b-col sm="12" lg="4">
            {{ d_userShopWdm['0']['parameters']['8']['name']['translation'][StoreLang] }}
          </b-col>
          <b-col sm="12" lg="8">
            <b-row style="margin: 3px;" v-for="(lang, lang_index) in StoreLangList.data">
              <b-col sm="12" lg="4">
                {{ lang.label }}
              </b-col>
              <b-col sm="12" lg="8">
                <input type="text" class="form-control" v-model="d_userShopData.shop_web_link[lang.label]">
              </b-col>
            </b-row>
          </b-col>
        </b-row>
        <b-row style="margin: 3px;">
          <b-col sm="12" lg="4">
            {{ d_userShopWdm['0']['parameters']['29']['name']['translation'][StoreLang] }}
          </b-col>
          <b-col sm="12" lg="8">
            <b-row style="margin: 3px;" v-for="(lang, lang_index) in StoreLangList.data">
              <b-col sm="12" lg="4">
                {{ lang.label }}
              </b-col>
              <b-col sm="12" lg="8">
                <input type="text" class="form-control" v-model="d_userShopData.mail[lang.label]">
              </b-col>
            </b-row>
          </b-col>
        </b-row>
        <b-row style="margin: 3px;">
          <b-col sm="12" lg="4">
            {{ d_userShopWdm['0']['parameters']['30']['name']['translation'][StoreLang] }}
          </b-col>
          <b-col sm="12" lg="8">
            <b-row style="margin: 3px;" v-for="(lang, lang_index) in StoreLangList.data">
              <b-col sm="12" lg="4">
                {{ lang.label }}
              </b-col>
              <b-col sm="12" lg="8">
                <input type="text" class="form-control" v-model="d_userShopData.phone[lang.label]">
              </b-col>
            </b-row>
          </b-col>
        </b-row>
        <b-row style="margin: 3px;">
          <b-col sm="12" lg="4">
            {{ d_userShopWdm['0']['parameters']['9']['name']['translation'][StoreLang] }}
          </b-col>
          <b-col sm="12" lg="8">
            <b-row style="margin: 3px;" v-for="(lang, lang_index) in StoreLangList.data">
              <b-col sm="12" lg="4">
                {{ lang.label }}
              </b-col>
              <b-col sm="12" lg="8">
                <input type="text" class="form-control" v-model="d_userShopData.bank_name_1[lang.label]">
              </b-col>
            </b-row>
          </b-col>
        </b-row>
        <b-row style="margin: 3px;">
          <b-col sm="12" lg="4">
            {{ d_userShopWdm['0']['parameters']['10']['name']['translation'][StoreLang] }}
          </b-col>
          <b-col sm="12" lg="8">
            <b-row style="margin: 3px;" v-for="(lang, lang_index) in StoreLangList.data">
              <b-col sm="12" lg="4">
                {{ lang.label }}
              </b-col>
              <b-col sm="12" lg="8">
                <input type="text" class="form-control" v-model="d_userShopData.bank_title_1[lang.label]">
              </b-col>
            </b-row>
          </b-col>
        </b-row>
        <b-row style="margin: 3px;">
          <b-col sm="12" lg="4">
            {{ d_userShopWdm['0']['parameters']['11']['name']['translation'][StoreLang] }}
          </b-col>
          <b-col sm="12" lg="8">
            <b-row style="margin: 3px;" v-for="(lang, lang_index) in StoreLangList.data">
              <b-col sm="12" lg="4">
                {{ lang.label }}
              </b-col>
              <b-col sm="12" lg="8">
                <input type="text" class="form-control" v-model="d_userShopData.iban_1[lang.label]">
              </b-col>
            </b-row>
          </b-col>
        </b-row>
        <b-row style="margin: 3px;">
          <b-col sm="12" lg="4">
            {{ d_userShopWdm['0']['parameters']['12']['name']['translation'][StoreLang] }}
          </b-col>
          <b-col sm="12" lg="8">
            <b-row style="margin: 3px;" v-for="(lang, lang_index) in StoreLangList.data">
              <b-col sm="12" lg="4">
                {{ lang.label }}
              </b-col>
              <b-col sm="12" lg="8">
                <input type="text" class="form-control" v-model="d_userShopData.bank_info[lang.label]">
              </b-col>
            </b-row>
          </b-col>
        </b-row>
        <b-row style="margin: 3px;">
          <b-col sm="12" lg="4">
            {{ d_userShopWdm['0']['parameters']['13']['name']['translation'][StoreLang] }}
          </b-col>
          <b-col sm="12" lg="8">
            <b-row style="margin: 3px;" v-for="(lang, lang_index) in StoreLangList.data">
              <b-col sm="12" lg="4">
                {{ lang.label }}
              </b-col>
              <b-col sm="12" lg="8">
                <input type="text" class="form-control" v-model="d_userShopData.bank_name_2[lang.label]">
              </b-col>
            </b-row>
          </b-col>
        </b-row>
        <b-row style="margin: 3px;">
          <b-col sm="12" lg="4">
            {{ d_userShopWdm['0']['parameters']['14']['name']['translation'][StoreLang] }}
          </b-col>
          <b-col sm="12" lg="8">
            <b-row style="margin: 3px;" v-for="(lang, lang_index) in StoreLangList.data">
              <b-col sm="12" lg="4">
                {{ lang.label }}
              </b-col>
              <b-col sm="12" lg="8">
                <input type="text" class="form-control" v-model="d_userShopData.bank_title_2[lang.label]">
              </b-col>
            </b-row>
          </b-col>
        </b-row>
        <b-row style="margin: 3px;">
          <b-col sm="12" lg="4">
            {{ d_userShopWdm['0']['parameters']['15']['name']['translation'][StoreLang] }}
          </b-col>
          <b-col sm="12" lg="8">
            <b-row style="margin: 3px;" v-for="(lang, lang_index) in StoreLangList.data">
              <b-col sm="12" lg="4">
                {{ lang.label }}
              </b-col>
              <b-col sm="12" lg="8">
                <input type="text" class="form-control" v-model="d_userShopData.iban_2[lang.label]">
              </b-col>
            </b-row>
          </b-col>
        </b-row>
        <b-row style="margin: 3px;">
          <b-col sm="12" lg="4">
            {{ d_userShopWdm['0']['parameters']['16']['name']['translation'][StoreLang] }}
          </b-col>
          <b-col sm="12" lg="8">
            <b-row style="margin: 3px;" v-for="(lang, lang_index) in StoreLangList.data">
              <b-col sm="12" lg="4">
                {{ lang.label }}
              </b-col>
              <b-col sm="12" lg="8">
                <input type="text" class="form-control" v-model="d_userShopData.bank_info_2[lang.label]">
              </b-col>
            </b-row>
          </b-col>
        </b-row>
        <b-row style="margin: 3px;">
          <b-col sm="12" lg="4">
            {{ d_userShopWdm['0']['parameters']['17']['name']['translation'][StoreLang] }}
          </b-col>
          <b-col sm="12" lg="8">
            <b-row style="margin: 3px;" v-for="(lang, lang_index) in StoreLangList.data">
              <b-col sm="12" lg="4">
                {{ lang.label }}
              </b-col>
              <b-col sm="12" lg="8">
                <input type="text" class="form-control" v-model="d_userShopData.bank_name_3[lang.label]">
              </b-col>
            </b-row>
          </b-col>
        </b-row>
        <b-row style="margin: 3px;">
          <b-col sm="12" lg="4">
            {{ d_userShopWdm['0']['parameters']['18']['name']['translation'][StoreLang] }}
          </b-col>
          <b-col sm="12" lg="8">
            <b-row style="margin: 3px;" v-for="(lang, lang_index) in StoreLangList.data">
              <b-col sm="12" lg="4">
                {{ lang.label }}
              </b-col>
              <b-col sm="12" lg="8">
                <input type="text" class="form-control" v-model="d_userShopData.bank_title_3[lang.label]">
              </b-col>
            </b-row>
          </b-col>
        </b-row>
        <b-row style="margin: 3px;">
          <b-col sm="12" lg="4">
            {{ d_userShopWdm['0']['parameters']['19']['name']['translation'][StoreLang] }}
          </b-col>
          <b-col sm="12" lg="8">
            <b-row style="margin: 3px;" v-for="(lang, lang_index) in StoreLangList.data">
              <b-col sm="12" lg="4">
                {{ lang.label }}
              </b-col>
              <b-col sm="12" lg="8">
                <input type="text" class="form-control" v-model="d_userShopData.iban_3[lang.label]">
              </b-col>
            </b-row>
          </b-col>
        </b-row>
        <b-row style="margin: 3px;">
          <b-col sm="12" lg="4">
            {{ d_userShopWdm['0']['parameters']['20']['name']['translation'][StoreLang] }}
          </b-col>
          <b-col sm="12" lg="8">
            <b-row style="margin: 3px;" v-for="(lang, lang_index) in StoreLangList.data">
              <b-col sm="12" lg="4">
                {{ lang.label }}
              </b-col>
              <b-col sm="12" lg="8">
                <input type="text" class="form-control" v-model="d_userShopData.bank_info_3[lang.label]">
              </b-col>
            </b-row>
          </b-col>
        </b-row>
        <b-row style="margin: 3px;">
          <b-col sm="12" lg="4">
            {{ d_userShopWdm['0']['parameters']['21']['name']['translation'][StoreLang] }}
          </b-col>
          <b-col sm="12" lg="8">
            <b-row style="margin: 3px;" v-for="(lang, lang_index) in StoreLangList.data">
              <b-col sm="12" lg="4">
                {{ lang.label }}
              </b-col>
              <b-col sm="12" lg="8">
                <input type="text" class="form-control" v-model="d_userShopData.bank_name_4[lang.label]">
              </b-col>
            </b-row>
          </b-col>
        </b-row>
        <b-row style="margin: 3px;">
          <b-col sm="12" lg="4">
            {{ d_userShopWdm['0']['parameters']['22']['name']['translation'][StoreLang] }}
          </b-col>
          <b-col sm="12" lg="8">
            <b-row style="margin: 3px;" v-for="(lang, lang_index) in StoreLangList.data">
              <b-col sm="12" lg="4">
                {{ lang.label }}
              </b-col>
              <b-col sm="12" lg="8">
                <input type="text" class="form-control" v-model="d_userShopData.bank_title_4[lang.label]">
              </b-col>
            </b-row>
          </b-col>
        </b-row>
        <b-row style="margin: 3px;">
          <b-col sm="12" lg="4">
            {{ d_userShopWdm['0']['parameters']['23']['name']['translation'][StoreLang] }}
          </b-col>
          <b-col sm="12" lg="8">
            <b-row style="margin: 3px;" v-for="(lang, lang_index) in StoreLangList.data">
              <b-col sm="12" lg="4">
                {{ lang.label }}
              </b-col>
              <b-col sm="12" lg="8">
                <input type="text" class="form-control" v-model="d_userShopData.iban_4[lang.label]">
              </b-col>
            </b-row>
          </b-col>
        </b-row>
        <b-row style="margin: 3px;">
          <b-col sm="12" lg="4">
            {{ d_userShopWdm['0']['parameters']['24']['name']['translation'][StoreLang] }}
          </b-col>
          <b-col sm="12" lg="8">
            <b-row style="margin: 3px;" v-for="(lang, lang_index) in StoreLangList.data">
              <b-col sm="12" lg="4">
                {{ lang.label }}
              </b-col>
              <b-col sm="12" lg="8">
                <input type="text" class="form-control" v-model="d_userShopData.bank_info_4[lang.label]">
              </b-col>
            </b-row>
          </b-col>
        </b-row>
        <b-row style="margin: 3px;">
          <b-col sm="12" lg="4">
            {{ d_userShopWdm['0']['parameters']['25']['name']['translation'][StoreLang] }}
          </b-col>
          <b-col sm="12" lg="8">
            <b-row style="margin: 3px;" v-for="(lang, lang_index) in StoreLangList.data">
              <b-col sm="12" lg="4">
                {{ lang.label }}
              </b-col>
              <b-col sm="12" lg="8">
                <input type="text" class="form-control" v-model="d_userShopData.bank_name_5[lang.label]">
              </b-col>
            </b-row>
          </b-col>
        </b-row>
        <b-row style="margin: 3px;">
          <b-col sm="12" lg="4">
            {{ d_userShopWdm['0']['parameters']['26']['name']['translation'][StoreLang] }}
          </b-col>
          <b-col sm="12" lg="8">
            <b-row style="margin: 3px;" v-for="(lang, lang_index) in StoreLangList.data">
              <b-col sm="12" lg="4">
                {{ lang.label }}
              </b-col>
              <b-col sm="12" lg="8">
                <input type="text" class="form-control" v-model="d_userShopData.bank_title_5[lang.label]">
              </b-col>
            </b-row>
          </b-col>
        </b-row>
        <b-row style="margin: 3px;">
          <b-col sm="12" lg="4">
            {{ d_userShopWdm['0']['parameters']['27']['name']['translation'][StoreLang] }}
          </b-col>
          <b-col sm="12" lg="8">
            <b-row style="margin: 3px;" v-for="(lang, lang_index) in StoreLangList.data">
              <b-col sm="12" lg="4">
                {{ lang.label }}
              </b-col>
              <b-col sm="12" lg="8">
                <input type="text" class="form-control" v-model="d_userShopData.iban_5[lang.label]">
              </b-col>
            </b-row>
          </b-col>
        </b-row>
        <b-row style="margin: 3px;">
          <b-col sm="12" lg="4">
            {{ d_userShopWdm['0']['parameters']['28']['name']['translation'][StoreLang] }}
          </b-col>
          <b-col sm="12" lg="8">
            <b-row style="margin: 3px;" v-for="(lang, lang_index) in StoreLangList.data">
              <b-col sm="12" lg="4">
                {{ lang.label }}
              </b-col>
              <b-col sm="12" lg="8">
                <input type="text" class="form-control" v-model="d_userShopData.bank_info_5[lang.label]">
              </b-col>
            </b-row>
          </b-col>
        </b-row>
        <b-row style="margin: 3px;">
          <b-col sm="12" lg="4">
            {{ d_userShopWdm['0']['parameters']['29']['name']['translation'][StoreLang] }}
          </b-col>
          <b-col sm="12" lg="8">
            <b-row style="margin: 3px;" v-for="(lang, lang_index) in StoreLangList.data">
              <b-col sm="12" lg="4">
                {{ lang.label }}
              </b-col>
              <b-col sm="12" lg="8">
                <input type="text" class="form-control" v-model="d_userShopData.mail[lang.label]">
              </b-col>
            </b-row>
          </b-col>
        </b-row>
        <b-row style="margin: 3px;">
          <b-col sm="12" lg="4">
            {{ d_userShopWdm['0']['parameters']['30']['name']['translation'][StoreLang] }}
          </b-col>
          <b-col sm="12" lg="8">
            <b-row style="margin: 3px;" v-for="(lang, lang_index) in StoreLangList.data">
              <b-col sm="12" lg="4">
                {{ lang.label }}
              </b-col>
              <b-col sm="12" lg="8">
                <input type="text" class="form-control" v-model="d_userShopData.phone[lang.label]">
              </b-col>
            </b-row>
          </b-col>
        </b-row>
        <b-row style="margin: 3px;">
          <b-col sm="12" lg="4">
            {{ d_userShopWdm['0']['parameters']['31']['name']['translation'][StoreLang] }}
          </b-col>
          <b-col sm="12" lg="8">
            <b-row style="margin: 3px;" v-for="(lang, lang_index) in StoreLangList.data">
              <b-col sm="12" lg="4">
                {{ lang.label }}
              </b-col>
              <b-col sm="12" lg="8">
                <input type="text" class="form-control" v-model="d_userShopData.short_name[lang.label]">
              </b-col>
            </b-row>
          </b-col>
        </b-row>
        <b-row style="margin: 3px;">
          <b-col sm="12" lg="4">
            {{ d_userShopWdm['0']['parameters']['32']['name']['translation'][StoreLang] }}
          </b-col>
          <b-col sm="12" lg="8">
            <b-row style="margin: 3px;" v-for="(lang, lang_index) in StoreLangList.data">
              <b-col sm="12" lg="4">
                {{ lang.label }}
              </b-col>
              <b-col sm="12" lg="8">
                <input type="text" class="form-control" v-model="d_userShopData.trade_register_number[lang.label]">
              </b-col>
            </b-row>
          </b-col>
        </b-row>

        <b-row style="margin: 3px;">
          <b-col sm="12" lg="6">
          </b-col>
          <b-col sm="12" lg="6">
            <b-button variant="secondary" @click="f_userShopRecord()">
              {{ StoreLangTranslation.data['save'][StoreLang] }}
            </b-button>
          </b-col>
        </b-row>
      </b-col>
      <b-col sm="12" lg="2"></b-col>
    </b-row>
  </div>
</template>

<script>
import { ClsWdm } from '@/wam/modules/wdm';
import store from '@/store';
import { ServiceFunctions } from '@/wam/modules/service';
import { mapGetters } from 'vuex';
export default {
  name: 'user_shop_record',
  computed: mapGetters({
    StoreLang: 'StoreLang',
    StoreLangList: 'StoreLangList',
    StoreLangTranslation: 'StoreLangTranslation',
    StoreFrontendSettings: 'StoreFrontendSettings',
    StoreDevice: 'StoreDevice',
  }),
  components: {},
  props: {},
  data () {
    return {
      d_user: '',
      d_frontendClientID: '',
      d_userShopClientID: '',
      d_frontendList: [],
      d_userShopWdm: '',
      d_userShopData: {
        'status': '0',
        'shop_name': {},
        'shop_logo': {},
        'shop_address': {},
        'shop_tax_office': {},
        'shop_tax_number': {},
        'tax_rate_percent': {},
        'mersis_no': {},
        'shop_web_link': {},
        'bank_name_1': {},
        'bank_title_1': {},
        'iban_1': {},
        'bank_info': {},
        'bank_name_2': {},
        'bank_title_2': {},
        'iban_2': {},
        'bank_info_2': {},
        'bank_name_3': {},
        'bank_title_3': {},
        'iban_3': {},
        'bank_info_3': {},
        'bank_name_4': {},
        'bank_title_4': {},
        'iban_4': {},
        'bank_info_4': {},
        'bank_name_5': {},
        'bank_title_5': {},
        'iban_5': {},
        'bank_info_5': {},
        'mail': {},
        'phone': {},
        'short_name': {},
        'trade_register_number': {},
      }
    };
  },
  created: function () {
    ClsWdm.getLayerWdm('usr_shp').then(resp_wdm_data => { this.d_userShopWdm = resp_wdm_data; });
    this.d_user = JSON.parse(localStorage.getItem('user'));
    this.f_langEligibleParameterProcess();
  },
  beforeMount () {},
  mounted: function () {
    if (this.$route.query.user_shop_client_id) {
      this.d_userShopClientID = this.$route.query.user_shop_client_id;
      this.f_userShopGet();
    }
    // console.log(this.StoreLangList);
    // console.log(this.StoreFrontendSettings);
    // console.log(this.d_userShopWdm);
  },
  methods: {
    f_langEligibleParameterProcess: function () {
      let lang_eligible_variables = ['shop_name', 'shop_logo', 'shop_address', 'shop_tax_office', 'shop_tax_number', 'tax_rate_percent', 'mersis_no', 'shop_web_link', 'bank_name_1', 'bank_title_1', 'iban_1', 'bank_info', 'bank_name_2', 'bank_title_2', 'iban_2', 'bank_info_2', 'bank_name_3', 'bank_title_3', 'iban_3', 'bank_info_3', 'bank_name_4', 'bank_title_4', 'iban_4', 'bank_info_4', 'bank_name_5', 'bank_title_5', 'iban_5', 'bank_info_5', 'mail', 'phone', 'short_name', 'trade_register_number'];
      for (let k in lang_eligible_variables) {
        for (let lang_index in this.StoreLangList.data) {
          if (!this.d_userShopData[lang_eligible_variables[k]][this.StoreLangList.data[lang_index].label]) {
            this.d_userShopData[lang_eligible_variables[k]][this.StoreLangList.data[lang_index].label] = '';
          }
        }
      }
    },
    f_userShopRecord: function () {
      let query = 'user_client_id=' + this.d_user.username;
      let data = {};
      if (this.d_userShopClientID) {
        query += '&user_shop_client_id=' + this.d_userShopClientID;
      }
      for (let i in this.d_userShopData) {
        data[i] = this.d_userShopData[i];
      }
      if (this.d_userShopClientID) {
        ServiceFunctions.user_shop_edit(query, data)
          .then(resp => {
            if (resp.data.status_code.substring(resp.data.status_code.length - 3, resp.data.status_code.length) == '000') {
              alert(this.StoreLangTranslation.data['process_completed'][this.StoreLang]);
            } else {
              alert(resp.data.status_code + ' = ' + resp.data.status_message);
            }
          });
      } else {
        ServiceFunctions.user_shop_new(query, data)
          .then(resp => {
            if (resp.data.status_code.substring(resp.data.status_code.length - 3, resp.data.status_code.length) == '000') {
              alert(this.StoreLangTranslation.data['process_completed'][this.StoreLang]);
            } else {
              alert(resp.data.status_code + ' = ' + resp.data.status_message);
            }
          });
      }
    },
    f_userShopGet: function () {
      let query = 'user_shop_client_id=' + this.d_userShopClientID;
      query += '&data_type=value_mode';
      let data = {};
      ServiceFunctions.user_shop_get(query, data)
        .then(resp => {
          if (resp.data.status_code.substring(resp.data.status_code.length - 3, resp.data.status_code.length) == '000') {
            if (resp.data.data) {
              this.d_userShopData = resp.data.data;
              this.f_langEligibleParameterProcess();
            } else {
              alert(this.StoreLangTranslation.data['process_completed'][this.StoreLang]);
            }
          } else {
            alert(resp.data.status_code + ' = ' + resp.data.status_message);
          }
        });
    },
  }
};

</script>

